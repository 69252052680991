import axios from 'axios';
import { getApiUrl } from './env';

const cache = new Map();
const CACHE_DURATION = 30000; // 30 seconds

const api = axios.create({
  baseURL: getApiUrl(),
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add request interceptor
api.interceptors.request.use(
  async (config) => {
    const cacheKey = `${config.method}-${config.url}`;
    const cached = cache.get(cacheKey);
    
    if (cached && Date.now() - cached.timestamp < CACHE_DURATION) {
      return Promise.reject({
        __CACHE: true,
        data: cached.data
      });
    }
    
    return config;
  },
  (error) => Promise.reject(error)
);

// Add response interceptor
api.interceptors.response.use(
  (response) => {
    const cacheKey = `${response.config.method}-${response.config.url}`;
    cache.set(cacheKey, {
      timestamp: Date.now(),
      data: response.data
    });
    return response;
  },
  (error) => {
    if (error.__CACHE) {
      return Promise.resolve({ data: error.data });
    }
    return Promise.reject(error);
  }
);

export default api;
