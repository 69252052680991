import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

interface User {
  email: string;
  name: string;
  password: string;
  role: string;
}

interface AuthContextType {
  isAuthenticated: boolean;
  userRole: string;
  userName: string;
  login: (email: string, password: string) => boolean;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const users: User[] = [
  { email: 'noouf3234@gmail.com', name: 'نوف', password: 'password1', role: 'user' },
  { email: 'mha86858@gmail.com', name: 'مها', password: 'password2', role: 'user' },
  { email: 'Dwian12311@gmail.com', name: 'ضي', password: 'password3', role: 'user' },
  { email: 'amalmagbal@gmail.com', name: 'اماني', password: 'password4', role: 'user' },
  { email: 'M00tairy@hotmail.com', name: 'ممدوح', password: 'password5', role: 'uploader' },
  { email: 'Kareemabdelmabood@gamil.Com', name: 'كريم', password: 'password6', role: 'user' },
  { email: 'Dinamohammed271@gmail.com', name: 'دينا', password: 'password7', role: 'user' },
  { email: 'Medoschumacher@gmail.com', name: 'احمد', password: 'password8', role: 'admin' },
  { email: 'kook1233@hotmail.com', name: 'خلود', password: 'password9', role: 'user' },
  { email: 'rooome-2008@hotmail.com', name: 'ريهام', password: 'password10', role: 'user' },
];

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [userName, setUserName] = useState('');

  const login = (email: string, password: string): boolean => {
    const user = users.find(u => u.email.toLowerCase() === email.toLowerCase() && u.password === password);
    if (user) {
      setIsAuthenticated(true);
      setUserRole(user.role);
      setUserName(user.name);
      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('userRole', user.role);
      localStorage.setItem('userName', user.name);
      return true;
    }
    return false;
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUserRole('');
    setUserName('');
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('userRole');
    localStorage.removeItem('userName');
  };

  useEffect(() => {
    const storedIsAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
    const storedUserRole = localStorage.getItem('userRole');
    const storedUserName = localStorage.getItem('userName');

    if (storedIsAuthenticated && storedUserRole && storedUserName) {
      setIsAuthenticated(true);
      setUserRole(storedUserRole);
      setUserName(storedUserName);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, userRole, userName, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
