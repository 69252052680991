import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Container, Row, Col, Form, Button, Table, Modal, Alert } from 'react-bootstrap';
import axios from 'axios';
import styles from '../styles/AdminPage.module.css';
import { API_BASE_URL } from '../config';
import FileList from '../components/FileList';
import { useTranslation } from '../hooks/useTranslation';
import api from '../utils/api';

interface Excel {
  _id: string;
  filename: string;
  originalname: string;
  size: number;
  uploadDate: string;
  data: any[];
  isTicketFile?: boolean;
  countryName?: string; 
}

const AdminPage: React.FC = () => {
  const { t } = useTranslation();
  const { userRole } = useAuth();
  const [ticketFiles, setTicketFiles] = useState<Excel[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [excels, setExcels] = useState<Excel[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [viewModal, setViewModal] = useState<{ 
    show: boolean; 
    data: any[]; 
    loading: boolean; 
    error?: string 
  }>({ 
    show: false, 
    data: [], 
    loading: false 
  });
  const [editModal, setEditModal] = useState<{ 
    show: boolean; 
    excel: Excel | null 
  }>({ 
    show: false, 
    excel: null 
  });
  const [ticketFile, setTicketFile] = useState<File | null>(null);
  const [ticketUploadError, setTicketUploadError] = useState<string | null>(null);

  // Add cache state
  const [lastFetch, setLastFetch] = useState<number>(0);
  const CACHE_DURATION = 30000; // 30 seconds

  const fetchData = useCallback(async (force = false) => {
    if (!userRole || (userRole !== 'admin' && userRole !== 'uploader')) {
      return;
    }

    // Check cache unless force refresh
    const now = Date.now();
    if (!force && now - lastFetch < CACHE_DURATION) {
      return;
    }

    try {
      setLoading(true);
      setError(null);
      
      const [excelsResponse, ticketsResponse] = await Promise.all([
        api.get('/excels'),
        api.get('/ticket-files')
      ]);
      
      setExcels(excelsResponse?.data || []);
      setTicketFiles(ticketsResponse?.data || []);
      setLastFetch(now);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Unknown error');
      console.error('Error fetching data:', err);
    } finally {
      setLoading(false);
    }
  }, [userRole, lastFetch]);

  useEffect(() => {
    let isMounted = true;

    const loadData = async () => {
      if (isMounted) {
        await fetchData();
      }
    };

    loadData();

    return () => {
      isMounted = false;
    };
  }, [fetchData]);

  if (userRole !== 'admin' && userRole !== 'uploader') {
    return (
      <div className={styles['admin-page']}>
        <Container>
          <Row>
            <Col md={12} className="text-center py-5">
              <Alert variant="danger">
                {t('unauthorized')}
              </Alert>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleTicketFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setTicketFile(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    try {
      setLoading(true);
      setError(null);
      console.log('Uploading file:', file.name);
      const response = await axios.post(`${API_BASE_URL}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('File upload response:', response.data);
      await fetchData();
      setFile(null);
    } catch (error) {
      console.error('Error uploading file:', error);
      setError(axios.isAxiosError(error) 
        ? `Failed to upload file: ${error.response?.data?.message || error.message}`
        : t('failedToUploadFile'));
    } finally {
      setLoading(false);
    }
  };

  const handleTicketUpload = async () => {
    if (!ticketFile) return;
  
    const formData = new FormData();
    formData.append('file', ticketFile);
  
    try {
      setLoading(true);
      const response = await axios.post(`${API_BASE_URL}/upload-tickets`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Ticket file uploaded:', response.data);
      const countryName = response.data.countryName;
      console.log('Country name:', countryName);
      await fetchData();
      setTicketFile(null);
      setTicketUploadError(null);
    } catch (error) {
      console.error('Error uploading ticket file:', error);
      setTicketUploadError(t('failedToUploadTicketFile'));
    } finally {
      setLoading(false);
    }
  };
  const handleView = async (fileId: string, isTicketFile: boolean) => {
    console.log('handleView called with:', { fileId, isTicketFile });
    try {
      setViewModal({ show: true, data: [], loading: true });
      const endpoint = isTicketFile ? 'ticket-file' : 'excel';
      const url = `${API_BASE_URL}/${endpoint}/${fileId}`;
      console.log('Fetching data from:', url);
      const response = await axios.get(url);
      console.log('Response received:', response.data);
      if (response.data && response.data.data) {
        setViewModal({ show: true, data: response.data.data, loading: false });
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error fetching file data:', error);
      let errorMessage = t('failedToFetchData');
      if (axios.isAxiosError(error)) {
        errorMessage += `: ${error.response?.status} ${error.response?.statusText}`;
      } else if (error instanceof Error) {
        errorMessage += `: ${error.message}`;
      }
      setViewModal({ show: true, data: [], loading: false, error: errorMessage });
    }
  };

  // const handleEdit = async (file: Excel, isTicketFile: boolean) => {
  //   console.log('handleEdit called with:', { file, isTicketFile });
  //   try {
  //     const endpoint = isTicketFile ? 'ticket-file' : 'excel';
  //     const url = `${API_BASE_URL}/${endpoint}/${file._id}`;
  //     console.log('Fetching data from:', url);
  //     const response = await axios.get(url);
  //     console.log('Response received:', response.data);
  //     if (response.data && response.data.data) {
  //       setEditModal({ show: true, excel: { ...file, data: response.data.data } });
  //     } else {
  //       throw new Error('Invalid response format');
  //     }
  //   } catch (error) {
  //     console.error('Error fetching file data for editing:', error);
  //     let errorMessage = 'Failed to fetch data for editing';
  //     if (axios.isAxiosError(error)) {
  //       errorMessage += `: ${error.response?.status} ${error.response?.statusText}`;
  //     } else if (error instanceof Error) {
  //       errorMessage += `: ${error.message}`;
  //     }
  //     setError(errorMessage);
  //   }
  // };

  const handleDelete = async (fileId: string, isTicketFile: boolean) => {
    if (window.confirm(t('confirmDeleteFile'))) {
      try {
        const endpoint = isTicketFile ? 'ticket-file' : 'excel';
        await axios.delete(`${API_BASE_URL}/${endpoint}/${fileId}`);
        await fetchData();
      } catch (error) {
        console.error('Error deleting file', error);
        setError(t('failedToDeleteFile'));
      }
    }
  };

  const handleSaveEdit = async (updatedExcel: Excel) => {
    try {
      const endpoint = updatedExcel.isTicketFile ? 'ticket-file' : 'excel';
      await axios.put(`${API_BASE_URL}/${endpoint}/${updatedExcel._id}`, updatedExcel);
      setEditModal({ show: false, excel: null });
      await fetchData();
    } catch (error) {
      console.error('Error updating file', error);
      setError(t('failedToUpdateFile'));
    }
  };

  return (
    <div className={styles['admin-page']}>
      <Container>
        <Row>
          <Col md={12}>
            <h1 className="text-white">Upload Excel Sheet</h1>
            <Form>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Control type="file" onChange={handleFileChange} accept=".xlsx, .xls" />
              </Form.Group>
              <Button variant="primary" onClick={handleUpload} disabled={!file || loading}>
                {loading ? t('uploading') : t('upload')}
              </Button>
            </Form>
            {error && <Alert variant="danger">{error}</Alert>}
            <h2 className="text-white">Uploaded Excel Sheets</h2>
            {loading ? (
              <p className={styles['loading-text']}>{t('loading')}</p>
            ) : (
              <>
                <FileList
                  files={excels}
                  onDelete={(id) => handleDelete(id, false)}
                  onView={(id) => handleView(id, false)}
                  title={'uploadedFiles'}
                  userRole={userRole}
                />
              </>
            )}
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <h2 className="text-white">Upload Ticket File</h2>
            <Form>
              <Form.Group controlId="formTicketFile" className="mb-3">
                <Form.Control type="file" onChange={handleTicketFileChange} accept=".xlsx, .xls" />
              </Form.Group>
              <Button variant="primary" onClick={handleTicketUpload} disabled={!ticketFile || loading}>
                {loading ? t('uploading') : t('uploadTicketFile')}
              </Button>
            </Form>
            {ticketUploadError && <Alert variant="danger">{ticketUploadError}</Alert>}
            <h2 className="text-white">Uploaded Ticket Files</h2>
            {loading ? (
              <p className={styles['loading-text']}>{t('loading')}</p>
            ) : (
              <FileList
                files={ticketFiles}
                onDelete={(id) => handleDelete(id, true)}
                onView={(id) => handleView(id, true)}
                title={'ticketFiles'}
                userRole={userRole}
              />
            )}
          </Col>
        </Row>
      </Container>

      {userRole === 'admin' && (
        <ViewModal
          show={viewModal.show}
          onHide={() => setViewModal({ show: false, data: [], loading: false })}
          data={viewModal.data}
          loading={viewModal.loading}
          error={viewModal.error}
        />
      )}
      <EditModal
        show={editModal.show}
        onHide={() => setEditModal({ show: false, excel: null })}
        excel={editModal.excel}
        onSave={handleSaveEdit}
      />
    </div>
  );
};

interface ViewModalProps {
  show: boolean;
  onHide: () => void;
  data: any[];
  loading: boolean;
  error?: string;
}

const ViewModal: React.FC<ViewModalProps> = ({ show, onHide, data, loading, error }) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const renderCellValue = (value: any) => {
    if (typeof value === 'object' && value !== null) {
      if ('formula' in value && 'result' in value) {
        return (
          <span title={`Formula: ${value.formula}`}>
            {value.result}
          </span>
        );
      } else if ('text' in value && 'hyperlink' in value) {
        return (
          <a href={value.hyperlink} target="_blank" rel="noopener noreferrer">
            {value.text}
          </a>
        );
      } else {
        return JSON.stringify(value);
      }
    }
    return value;
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <Modal show={show} onHide={onHide} size="xl" className={styles['view-modal']}>
      <Modal.Header closeButton>
        <Modal.Title>{t('viewExcelData')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <p>{t('loading')}</p>}
        {error && <p className={styles['text-danger']}>{error}</p>}
        {!loading && !error && data.length === 0 && <p>{t('noDataAvailable')}</p>}
        {!loading && !error && data.length > 0 && (
          <>
            <div className={styles['table-container']}>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    {Object.keys(data[0]).map((key) => (
                      <th key={key}>{key}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((row, index) => (
                    <tr key={index}>
                      {Object.entries(row).map(([key, value]) => (
                        <td key={key}>{renderCellValue(value)}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className={styles['pagination-container']}>
              <Button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                {t('previous')}
              </Button>
              <span>{`${t('page')} ${currentPage} ${t('of')} ${Math.ceil(data.length / itemsPerPage)}`}</span>
              <Button
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === Math.ceil(data.length / itemsPerPage)}
              >
                {t('next')}
              </Button>
              <Form.Control
                as="select"
                value={itemsPerPage}
                onChange={(e) => setItemsPerPage(Number(e.target.value))}
                className={styles['items-per-page']}
              >
                <option value="10">{t('tenPerPage')}</option>
                <option value="25">{t('twentyFivePerPage')}</option>
                <option value="50">{t('fiftyPerPage')}</option>
                <option value="100">{t('hundredPerPage')}</option>
              </Form.Control>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

interface EditModalProps {
  show: boolean;
  onHide: () => void;
  excel: Excel | null;
  onSave: (updatedExcel: Excel) => void;
}

const EditModal: React.FC<EditModalProps> = ({ show, onHide, excel, onSave }) => {
  const { t } = useTranslation();
  const [editedData, setEditedData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    if (excel && excel.data) {
      setEditedData(excel.data);
    } else {
      setEditedData([]);
    }
  }, [excel]);

  const handleInputChange = (rowIndex: number, columnName: string, value: string) => {
    const updatedData = [...editedData];
    updatedData[rowIndex][columnName] = value;
    setEditedData(updatedData);
  };

  const handleSave = () => {
    if (excel) {
      onSave({ ...excel, data: editedData });
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = editedData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{t('editExcelData')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {editedData.length > 0 ? (
          <>
            <div className={styles['table-container']}>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    {Object.keys(editedData[0]).map((key) => (
                      <th key={key}>{key}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {Object.entries(row).map(([key, value]) => (
                        <td key={key}>
                          <Form.Control
                            type="text"
                            value={typeof value === 'object' ? JSON.stringify(value) : String(value)}
                            onChange={(e) => handleInputChange(rowIndex + indexOfFirstItem, key, e.target.value)}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className={styles['pagination-container']}>
              <Button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                {t('previous')}
              </Button>
              <span>{`${t('page')} ${currentPage} ${t('of')} ${Math.ceil(editedData.length / itemsPerPage)}`}</span>
              <Button
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === Math.ceil(editedData.length / itemsPerPage)}
              >
                {t('next')}
              </Button>
              <Form.Control
                as="select"
                value={itemsPerPage}
                onChange={(e) => setItemsPerPage(Number(e.target.value))}
                className={styles['items-per-page']}
              >
                <option value="10">{t('tenPerPage')}</option>
                <option value="25">{t('twentyFivePerPage')}</option>
                <option value="50">{t('fiftyPerPage')}</option>
                <option value="100">{t('hundredPerPage')}</option>
              </Form.Control>
            </div>
          </>
        ) : (
          <p>{t('noDataAvailableForEditing')}</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide} className={styles['btn-secondary']}>
          {t('close')}
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={editedData.length === 0} className={styles['btn-primary']}>
          {t('saveChanges')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AdminPage;
