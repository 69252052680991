import { EOL } from 'os';
import type { Translations } from '../types/translations';

export const translations: Translations = {
  ar: {
    // General
    appName: 'الركائز البشرية',
    welcome: 'مرحباً بك في الركائز البشرية',
    welcomeSubtitle: 'نحن سعداء بانضمامك إلينا. يرجى تسجيل الدخول أو إنشاء حساب جديد للمتابعة.',
    copyright: '© 2024 الركائز البشرية. جميع الحقوق محفوظة.',
    toggleTheme: 'تبديل السمة',
    loginToAccess: 'يرجى تسجيل الدخول للوصول إلى هذه الصفحة',
    invalidData: 'البيانات غير موجودة',
    noFiles: 'لا يوجد ملفات محملة',
    notAvailable: 'غير متاح',
    // Navigation
    home: 'الرئيسية',
    search: 'البحث',
    admin: 'الإدارة',
    login: 'تسجيل الدخول',
    register: 'التسجيل',
    logout: 'تسجيل الخروج',
  
    // Auth
    email: 'البريد الإلكتروني',
    password: 'كلمة المرور',
    name: 'الاسم',
    enterEmail: 'أدخل البريد الإلكتروني',
    enterPassword: 'أدخل كلمة المرور',
    enterName: 'أدخل اسمك',
    
    // Search
    searchTitle: 'البحث في الملفات المحملة',
    searchByCode: 'البحث بالكود فقط',
    enterSearchTerm: 'أدخل مصطلح البحث',
    enterCode: 'أدخل الكود',
    loading: 'جاري التحميل...',
    error: 'حدث خطأ ما',
    noResults: 'لا توجد نتائج',
    searchResults: 'نتائج البحث',
    day: 'يوم',
    searchError: 'حدث خطأ أثناء البحث. يرجى المحاولة مرة أخرى.',
    
    // Admin
    uploadFile: 'رفع ملف',
    uploadTicketFile: 'رفع ملف تذاكر',
    uploading: 'جاري الرفع...',
    noPermission: 'عذراً، لا تملك الصلاحية للوصول إلى هذه الصفحة',
    
    // Employee Card
    sponsorName: 'اسم الكفيل',
    code: 'الكود',
    date: 'التاريخ',
    arrivalCity: 'مدينة الوصول',
    deliveryCity: 'مدينة التسليم',
    
    // Theme
    theme: 'السمة',
    language: 'اللغة',

    // Header
    toggleMenu: 'تبديل القائمة',

    // DataTable
    noData: 'لا يوجد بيانات متاحة',
    na: 'N/A',
    yes: 'نعم',
    no: 'لا',

    // Employee Card
    visaStatus: 'حالة التأشيرة',
    office: 'المكتب',
    phoneNumber: 'رقم الجوال',
    visaNumber: 'رقم التأشيرة',
    idNumber: 'رقم الهوية',
    contractNumber: 'رقم العقد',
    visaDate: 'تاريخ التفييز',

    // File List
    fileName: 'اسم الملف',
    size: 'الحجم',
    uploadDate: 'تاريخ الرفع',
    countryName: 'الدولة',
    viewData: 'عرض البيانات',
    delete: 'حذف',
    title: 'الملفات المحملة',
    uploadedFiles: 'الملفات المحملة',
    ticketFiles: 'ملفات التذاكر',
    generalFiles: 'الملفات العامة',

    // File Upload
    uploadExcelFile: 'رفع ملف Excel',
    errorReadingFile: 'خطأ في قراءة الملف. يرجى المحاولة مرة اخرى.',
    errorParsingFile: 'خطأ في تحليل الملف:',
    unknownError: 'خطأ غير معروف',
    upload: 'رفع',
    emptyFile: 'يبدو أن الملف الإكسل فارغ أو منسق بشكل غير مناسب.',

    // Footer
    footer: '© 2024 الركائز البشرية. جميع الحقوق محفوظة.',

    // Login
    invalidEmailOrPassword: 'البريد الإلكتروني أو كلمة المرور غير موجودة',

    // Search
    searchByCodeOrName: 'البحث بالكود أو الاسم',
    searchFiles: 'البحث في الملفات المحملة',
    searchByCodeOnly: 'البحث بالكود فقط',
    noResultsFound: 'لا توجد نتائج',
    result: 'نتيجة',
    results: 'نتائج',
    

    // Side Menu
    sideMenu: 'القائمة الجانبية',
    closeSideMenu: 'إغلاق القائمة',
    darkMode: 'الوضع الليلي',
    lightMode: 'الوضع النهاري',
    arabic: 'العربية',
    english: 'الإنجليزية',

    // Welcome Message
    welcomeMessage: 'مرحبًا بك في الركائز البشرية',
    welcomeSubtitleMessage: 'نحن سعداء بانضمامك إلينا. يرجى تسجيل الدخول أو إنشاء حساب جديد للمتابعة.',

    // Admin Page
    failedToFetchExcelSheets: 'فشل في جلب ملفات Excel',
    failedToFetchTicketFiles: 'فشل في جلب ملفات التذاكر',
    failedToUploadFile: 'فشل في رفع الملف',
    failedToUploadTicketFile: 'فشل في رفع ملف التذاكر',
    failedToFetchData: 'فشل في جلب البيانات',
    confirmDeleteFile: 'هل أنت متأكد أنك تريد حذف هذا الملف؟',
    failedToDeleteFile: 'فشل في حذف الملف',
    failedToUpdateFile: 'فشل في تحديث الملف',
    viewExcelData: 'عرض بيانات Excel',
    noDataAvailable: 'لا يوجد بيانات متاحة.',
    page: 'الصفحة',
    of: 'من',
    previous: 'السابق',
    next: 'التالي',
    editExcelData: 'تعديل بيانات Excel',
    noDataAvailableForEditing: 'لا يوجد بيانات متاحة للتعديل.',
    close: 'إغلاق',
    saveChanges: 'حفظ التعديلات',
    unauthorized: 'ليس لديك الصلاحية للوصول إلى هذه الصفحة',
    tenPerPage: '10 صفحة',
    twentyFivePerPage: '25 صفحة',
    fiftyPerPage: '50 صفحة',
    hundredPerPage: '100 صفحة',

    //Home
    welcomeHome: 'مرحبًا بك،',

  },
  en: {
    // General
    appName: 'Al Rakaize',
    welcome: 'Welcome to Al Rakaize',
    welcomeSubtitle: 'We are happy to have you with us. Please login or create a new account to continue.',
    copyright: '© 2024 Al Rakaize. All rights reserved.',
    toggleTheme: 'Toggle Theme',
    loginToAccess: 'Please login to access this page',
    invalidData: 'The data is not available',
    noFiles: 'No files uploaded',
    notAvailable: 'Not Available',
    // Navigation
    home: 'Home',
    search: 'Search',
    admin: 'Admin',
    login: 'Login',
    register: 'Register',
    logout: 'Logout',
    
    // Auth
    email: 'Email',
    password: 'Password',
    name: 'Name',
    enterEmail: 'Enter your email',
    enterPassword: 'Enter your password',
    enterName: 'Enter your name',
    
    // Search
    searchTitle: 'Search Uploaded Files',
    searchByCode: 'Search by Code Only',
    enterSearchTerm: 'Enter search term',
    enterCode: 'Enter code',
    loading: 'Loading...',
    error: 'An error occurred',
    noResults: 'No results found',
    searchResults: 'Search Results',
    
    // Admin
    uploadFile: 'Upload File',
    uploadTicketFile: 'Upload Ticket File',
    uploading: 'Uploading...',
    noPermission: 'Sorry, you do not have permission to access this page',
    
    // Employee Card
    sponsorName: 'Sponsor Name',
    code: 'Code',
    date: 'Date',
    arrivalCity: 'Arrival City',
    deliveryCity: 'Delivery City',
    
    // Theme
    theme: 'Theme',
    language: 'Language',

    // Header
    toggleMenu: 'Toggle Menu',

    // DataTable
    noData: 'No data available',
    na: 'N/A',
    yes: 'Yes',
    no: 'No',

    // Employee Card
    visaStatus: 'Visa Status',
    office: 'Office',
    phoneNumber: 'Phone Number',
    visaNumber: 'Visa Number',
    idNumber: 'ID Number',
    contractNumber: 'Contract Number',
    visaDate: 'Visa Date',

    // File List
    fileName: 'File Name',
    size: 'Size',
    uploadDate: 'Upload Date',
    countryName: 'Country Name',
    viewData: 'View Data',
    delete: 'Delete',
    title: 'Uploaded Files',
    uploadedFiles: 'Uploaded Files',
    ticketFiles: 'Ticket Files',
    generalFiles: 'General Files',

    // File Upload
    uploadExcelFile: 'Upload Excel File',
    errorReadingFile: 'Error reading file. Please try again.',
    errorParsingFile: 'Error parsing file:',
    unknownError: 'Unknown error',
    upload: 'Upload',
    emptyFile: 'The Excel file appears to be empty or improperly formatted.',

    // Footer
    footer: '© 2024 Al Rakaize. All rights reserved.',

    // Login
    invalidEmailOrPassword: 'Invalid email or password',

    // Search
    searchByCodeOrName: 'Search by Name or Code',
    searchFiles: 'Search Uploaded Files',
    searchByCodeOnly: 'Search by Code Only',
    noResultsFound: 'No results found',
    result: 'Result',
    results: 'Results',
    day: 'Day',
    searchError: 'An error occurred during the search. Please try again.',

    // Side Menu
    sideMenu: 'Side Menu',
    closeSideMenu: 'Close Side Menu',
    darkMode: 'Dark Mode',
    lightMode: 'Light Mode',
    arabic: 'Arabic',
    english: 'English',

    // Welcome Message
    welcomeMessage: 'Welcome to Al Rakaize',
    welcomeSubtitleMessage: 'We are happy to have you with us. Please login or create a new account to continue.',

    // Admin Page
    failedToFetchExcelSheets: 'Failed to fetch Excel sheets',
    failedToFetchTicketFiles: 'Failed to fetch Ticket files',
    failedToUploadFile: 'Failed to upload file',
    failedToUploadTicketFile: 'Failed to upload ticket file',
    failedToFetchData: 'Failed to fetch data',
    confirmDeleteFile: 'Are you sure you want to delete this file?',
    failedToDeleteFile: 'Failed to delete file',
    failedToUpdateFile: 'Failed to update file',
    viewExcelData: 'View Excel Data',
    noDataAvailable: 'No data available.',
    page: 'Page',
    of: 'of',
    previous: 'Previous',
    next: 'Next',
    editExcelData: 'Edit Excel Data',
    noDataAvailableForEditing: 'No data available for editing.',
    close: 'Close',
    saveChanges: 'Save Changes',
    unauthorized: 'You are not authorized to access this page',
    tenPerPage: '10 per page',
    twentyFivePerPage: '25 per page',
    fiftyPerPage: '50 per page',
    hundredPerPage: '100 per page',


    //Home
    welcomeHome: 'Welcome to Al Rakaize',

  }
};
