import React, { useState } from 'react';
import { ListGroup, Button, Collapse, Card } from 'react-bootstrap';
import { FaChevronDown, FaChevronUp, FaTrash, FaTable } from 'react-icons/fa';
import styles from '../styles/FileList.module.css';
import { formatDate } from '../utils/dateUtils';
import { useTranslation } from '../hooks/useTranslation';

interface FileListProps {
  files: {
    _id: string;
    filename: string;
    originalname: string;
    size: number;
    uploadDate: string;
    countryName?: string;
  }[];
  onDelete: (id: string) => void;
  onView: (id: string) => void;
  title: 'uploadedFiles' | 'ticketFiles' | 'generalFiles';
  userRole: string;
}

const FileList: React.FC<FileListProps> = ({ files, onDelete, onView, title, userRole }) => {
  const [expandedId, setExpandedId] = useState<string | null>(null);
  const { t } = useTranslation();
  const toggleExpand = (id: string) => {
    setExpandedId(expandedId === id ? null : id);
  };

  const formatSize = (bytes: number) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const canDelete = userRole === 'admin';
  const canView = userRole === 'admin';

  if (!Array.isArray(files)) {
    console.error('Invalid files prop:', files);
    return <div className={styles.error}>{t('invalidData')}</div>;
  }

  if (files.length === 0) {
    return <div className={styles.empty}>{t('noFiles')}</div>;
  }

  return (
    <Card className="mb-4">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <h3 className="mb-0">{t(title)}</h3>
      </Card.Header>
      <ListGroup variant="flush">
        {files.map((file) => (
          <ListGroup.Item key={file._id} className={styles.fileItem}>
            <div
              className={styles.fileHeader}
              onClick={() => toggleExpand(file._id)}
              role="button"
            >
              <div className={styles.fileInfo}>
                <span className={styles.fileName}>{file.originalname}</span>
                {expandedId !== file._id && (
                  <span className={styles.fileMetadata}>
                    {formatSize(file.size)} • {formatDate(file.uploadDate)}
                  </span>
                )}
              </div>
              {expandedId === file._id ? <FaChevronUp /> : <FaChevronDown />}
            </div>

            <Collapse in={expandedId === file._id}>
              <div className={styles.fileDetails}>
                <div className={styles.detailsGrid}>
                  <div>
                    <strong>{t('fileName')}:</strong> {file.filename}
                  </div>
                  <div>
                    <strong>{t('size')}:</strong> {formatSize(file.size)}
                  </div>
                  <div>
                    <strong>{t('uploadDate')}:</strong> {formatDate(file.uploadDate)}
                  </div>
                  {file.countryName && (
                    <div>
                      <strong>{t('countryName')}:</strong> {file.countryName}
                    </div>
                  )}
                </div>
                <div className={styles.actions}>
                  {canView && (
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        onView(file._id);
                      }}
                    >
                      <FaTable className="me-1" /> {t('viewData')}
                    </Button>
                  )}
                  {canDelete && (
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        onDelete(file._id);
                      }}
                    >
                      <FaTrash className="me-1" /> {t('delete')}
                    </Button>
                  )}
                </div>
              </div>
            </Collapse>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Card>
  );
};

export default FileList;