// src/components/Header.tsx
import React from 'react';
import { Navbar, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';
import { useTranslation } from '../hooks/useTranslation';
import styles from '../styles/Header.module.css';
import logoDark from '../assets/logo.png';
import logoLight from '../assets/logo1.png';

interface HeaderProps {
  onMenuToggle: () => void;
}

const Header: React.FC<HeaderProps> = ({ onMenuToggle }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const logo = theme === 'dark' ? logoDark : logoLight;

  return (
    <Navbar className={styles.header}>
      <Container>
        <div className={styles.logoContainer}>
          <Link to="/" className={styles.logoLink}>
            <img src={logo} alt={t('appName')} className={styles.logo} />
          </Link>
          <Navbar.Brand as={Link} to="/" className={styles.brandText}>
            {t('appName')}
          </Navbar.Brand>
        </div>
        <button 
          className={styles.menuToggle}
          onClick={onMenuToggle}
          aria-label={t('toggleMenu')}
        >
          <span className={styles.menuToggleIcon}></span>
        </button>
      </Container>
    </Navbar>
  );
};

export default Header;
