export const getApiUrl = () => {
  if (process.env.NODE_ENV === 'development') {
    return 'http://localhost:10000/api';
  }
  // Use HTTPS without www to match certificate
  return 'https://alrakaize.com/api';
};

export const getPublicUrl = () => {
  return window.location.origin;
};