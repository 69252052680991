// src/components/login.tsx
import React, { useState } from 'react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo1.png';
import styles from '../styles/Login.module.css';
import WelcomeMessage from './WelcomeMessage';
import { useAuth } from '../contexts/AuthContext';
import { useTranslation } from '../hooks/useTranslation';
const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();
  const { t } = useTranslation();
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    if (login(email, password)) {
      navigate('/');
    } else {
      setError(t('invalidEmailOrPassword'));
    }
  };

  return (
    <div className={styles['login-page']}>
      <div className={styles['login-content']}>
        <WelcomeMessage className="text-white" />
        <Card className={styles['login-card']}>
          <Card.Body>
            <div className="text-center mb-4">
              <img src={logo} alt="الركائز البشرية" className={styles.logo1} />
              <h2 className="mt-3 text-white">{t('login')}</h2>
            </div>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label>{t('email')}</Form.Label>
                <div className="input-group">
                  <Form.Control
                    type="email"
                    placeholder={t('email')}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <span className={`input-group-text ${styles['input-group-text']}`}>
                    <FontAwesomeIcon  icon={faEnvelope} />
                  </span>
                </div>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formPassword">
                <Form.Label>{t('password')}</Form.Label>
                <div className="input-group">
                  <Form.Control
                    type="password"
                    placeholder={t('password')}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <span className={`input-group-text ${styles['input-group-text']}`}>
                    <FontAwesomeIcon  icon={faLock} />
                  </span>
                </div>
              </Form.Group>

              <Button variant="primary" type="submit" className="w-100">
                {t('login')}
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default Login;
