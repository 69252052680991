import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from '../contexts/AuthContext';
import { ThemeProvider } from '../contexts/ThemeContext';
import Header from '../components/Header';
import SideMenu from '../components/SideMenu';
import Home from './Home';
import Login from '../components/login';
import AdminPage from './AdminPage';
import SearchPage from './SearchPage';
import LoadingPage from '../components/LoadingPage';
import Footer from '../components/Footer';
import ProtectedRoute from '../components/ProtectedRoute';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/variables.css';
import '../styles/global.css';
import { API_BASE_URL } from '../config';

const App: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isSideMenuOpen, setIsSideMenuOpen] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const handleMenuToggle = React.useCallback(() => {
    setIsSideMenuOpen(prev => !prev);
  }, []);

  const handleMenuClose = React.useCallback(() => {
    setIsSideMenuOpen(false);
  }, []);

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      console.log('API URL:', API_BASE_URL);
    }
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <AuthProvider>
      <ThemeProvider>
        <BrowserRouter>
          <div className="app-wrapper">
            <div className="main-content">
              <Header onMenuToggle={handleMenuToggle} />
              <main>
                <div className="page-content">
                  <SideMenu isOpen={isSideMenuOpen} onClose={handleMenuClose} />
                  <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
                    <Route path="/admin" element={<ProtectedRoute><AdminPage /></ProtectedRoute>} />
                    <Route path="/search" element={<ProtectedRoute><SearchPage /></ProtectedRoute>} />
                  </Routes>
                </div>
              </main>
            </div>
            <Footer />
          </div>
        </BrowserRouter>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
