import React from 'react';
import { Container } from 'react-bootstrap';
import styles from '../styles/WelcomeMessage.module.css';
import { useTranslation } from '../hooks/useTranslation';
interface WelcomeMessageProps {
  className?: string;
}

const WelcomeMessage: React.FC<WelcomeMessageProps> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <Container className={`${styles['welcome-message']} text-center ${className || ''}`}>
      <h1>{t('welcome')}</h1>
      <p>{t('welcomeSubtitle')}</p>
    </Container>
  );
};

export default WelcomeMessage;