import React, { useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import axios from 'axios';
import { Container, Form,Card } from 'react-bootstrap';
import styles from '../styles/SearchPage.module.css';
//import WelcomeMessage from '../components/WelcomeMessage';
import ErrorBoundary from '../components/ErrorBoundary';
import EmployeeCard from '../components/EmployeeCard';
import { API_BASE_URL } from '../config';
import { formatDate } from '../utils/dateUtils';
import { useTranslation } from '../hooks/useTranslation';


interface SearchResult {
  filename: string;
  data: {
    'الكود'?: string;
    'إسم الكفيل'?: string;
    'اليوم'?: string;
    'التاريخ'?: string;
    'مدينة الوصول'?: string;
    'مدينة التسليم'?: string;
    [key: string]: any;
  };
  isTicketFile?: boolean;
  isExactMatch?: boolean;
}

const SearchPage: React.FC = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [codeOnly, setCodeOnly] = useState(false);

  const debouncedSearch = useCallback(
    debounce(async (term: string, searchByCode: boolean) => {
      if (!term || (searchByCode && term.length < 1)) {
        setSearchResults([]);
        return;
      }
  
      setIsLoading(true);
      setError(null);
  
      try {
        const [generalSearchResponse, ticketSearchResponse] = await Promise.all([
          axios.get(`${API_BASE_URL}/search?term=${term}&codeOnly=${searchByCode}`),
          axios.get(`${API_BASE_URL}/search-tickets?term=${term}&codeOnly=${searchByCode}`)
        ]);
  
        const combinedResults = [
          ...generalSearchResponse.data.map((result: SearchResult) => ({
            ...result,
            isTicketFile: false
          })),
          ...ticketSearchResponse.data.map((result: SearchResult) => ({
            ...result,
            isTicketFile: true
          }))
        ];
  
        setSearchResults(combinedResults);
        console.log('Combined search results:', combinedResults);
      } catch (err) {
        console.error('Search error:', err);
        setError(t('searchError'));
      } finally {
        setIsLoading(false);
      }
    }, 300),
    [setSearchResults, setIsLoading, setError, API_BASE_URL]
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchTerm(term);
    debouncedSearch(term, codeOnly);
  };

  const handleCodeOnlyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newCodeOnly = e.target.checked;
    setCodeOnly(newCodeOnly);
    debouncedSearch(searchTerm, newCodeOnly);
  };

  const renderTicketCard = (result: SearchResult) => (
    <Card className={`${styles['employee-card']} ${result.isExactMatch ? styles['exact-match'] : ''}`}>
      <Card.Body>
        <Card.Title>{result.data['إسم الكفيل']}</Card.Title>
        <Card.Text>
          <strong>{t('code')}:</strong> {result.data['الكود']}
        </Card.Text>
        <Card.Text>
          <strong>{t('day')}:</strong> {result.data['اليوم']}
        </Card.Text>
        <Card.Text>
          <strong>{t('date')}:</strong> {formatDate(result.data['التاريخ'])}
        </Card.Text>
        <Card.Text>
          <strong>{t('arrivalCity')}:</strong> {result.data['مدينة لوصول']}
        </Card.Text>
        <Card.Text>
          <strong>{t('deliveryCity')}:</strong> {result.data['مدينة لتسليم']}
        </Card.Text>
      </Card.Body>
    </Card>
  );

  const renderResults = (results: SearchResult[], isTicket: boolean) => {
    const filteredResults = results.filter(r => {
      if (!r || !r.data) return false;
      return r.isTicketFile === isTicket;
    });
    
    return (
      <div className={styles['search-results-section']}>
        <div className={styles['section-header']}>
          <h2 className={`${styles['section-title']} ${styles['brand-text']}`}>
            {isTicket ? t('ticketFiles') : t('searchFiles')}
          </h2>
          <span className={`${styles['results-count']} ${styles['brand-text']}`}>
            {filteredResults.length} {filteredResults.length === 1 ? t('result') : t('results')}
          </span>
        </div>
        
        <div className={styles['results-container']}>
          {filteredResults.length > 0 ? (
            <div className={styles['search-results-container']}>
              {filteredResults.map((result, index) => (
                <div key={index} className={styles['result-item']}>
                  {isTicket ? renderTicketCard(result) : <EmployeeCard employee={result.data} />}
                </div>
              ))}
            </div>
          ) : (
            <div className={styles['empty-state']}>
              <span className={styles['empty-state-icon']}>🔍</span>
              <p className={`${styles['empty-state-text']} ${styles['brand-text']}`}>
                {t('noResultsFound')} {isTicket ? t('ticketFiles') : t('searchFiles')}
              </p>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <ErrorBoundary>
      <div className={styles['search-page']}>
        <Container className={styles['search-content']}>
          <h1 className={styles['search-title']}>{t('searchFiles')}</h1>
          <Form>
            <Form.Group controlId="searchTerm">
              <Form.Control
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder={codeOnly ? t('enterCode') : t('enterSearchTerm')}
                className={styles['form-control']}
              />
            </Form.Group>
            <Form.Group controlId="codeOnly" className={styles['form-check']}>
              <Form.Check
                className={styles['checkbox-label']}
                type="checkbox"
                label={t('searchByCodeOnly')}
                checked={codeOnly}
                onChange={handleCodeOnlyChange}
              />
            </Form.Group>
          </Form>
          {isLoading && <p className={styles['loading-text']}>{t('loading')}</p>}
          {error && <p className={styles['error-text']}>{error}</p>}
          {searchResults.length === 0 && !isLoading && !error && searchTerm && (
            <p className={styles['no-results-text']}>
              {t('noResultsFound')} {codeOnly ? `للكود "${searchTerm}"` : `لـ "${searchTerm}"`}
            </p>
          )}
          {searchResults.length > 0 && (
            <div className={styles['search-results-wrapper']}>
              {renderResults(searchResults, false)}
              {renderResults(searchResults, true)}
            </div>
          )}
        </Container>
      </div>
    </ErrorBoundary>
  );
};
export default SearchPage;

