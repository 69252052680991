import React, { useState } from 'react';
import { Nav, Dropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useTheme } from '../contexts/ThemeContext';
import { FaSun, FaMoon, FaGlobe } from 'react-icons/fa';
import styles from '../styles/SideMenu.module.css';
import { useTranslation } from '../hooks/useTranslation';
interface SideMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

const SideMenu: React.FC<SideMenuProps> = ({ isOpen, onClose }) => {
  const { isAuthenticated, userRole, logout } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    onClose();
    navigate('/login');
  };

  return (
    <>
      <div 
        className={`${styles.overlay} ${isOpen ? styles.overlayVisible : ''}`}
        onClick={onClose}
        aria-hidden="true"
      />
      <div 
        className={`${styles.sideMenu} ${isOpen ? styles.open : ''}`}
        role="dialog"
        aria-modal="true"
        aria-label={t('sideMenu')}
      >
        <button 
          className={styles.closeButton} 
          onClick={onClose}
          aria-label={t('closeSideMenu')}
        >
          ×
        </button>
        <div className={styles.controls}>
          <ThemeSwitcher />
          <LanguageSelector />
        </div>
        <Nav className={styles.menuItems}>
          <Nav.Link as={Link} to="/" onClick={onClose}>{t('home')}</Nav.Link>
          {isAuthenticated && (
            <>
              <Nav.Link as={Link} to="/search" onClick={onClose}>{t('search')}</Nav.Link>
              {userRole !== 'user' && (
                <Nav.Link as={Link} to="/admin" onClick={onClose}>{t('admin')}</Nav.Link>
              )}
              <Nav.Link onClick={handleLogout}>{t('logout')}</Nav.Link>
            </>
          )}
          {!isAuthenticated && (
            <Nav.Link as={Link} to="/login" onClick={onClose}>{t('login')}</Nav.Link>
          )}
        </Nav>
      </div>
    </>
  );
};
const ThemeSwitcher = () => {
  const { theme, toggleTheme } = useTheme();
  const { t } = useTranslation();

  return (
    <div 
      className={styles.themeSwitcher} 
      onClick={toggleTheme}
      role="button"
      aria-pressed={theme === 'dark'}
      aria-label={t('toggleTheme')}
    >
      <div className={styles.switcherIcons}>
        <FaSun className={styles.sunIcon} />
        <FaMoon className={styles.moonIcon} />
      </div>
      <div className={`${styles.switcherHandle} ${theme === 'dark' ? styles.dark : ''}`} />
    </div>
  );
};

const LanguageSelector = () => {
  const { language, setLanguage, t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);
  
  const handleLanguageChange = (newLang: 'ar' | 'en') => {
    setLanguage(newLang);
    setIsOpen(false);
  };

  return (
    <div className={styles.languageSelector}>
      <button 
        className={styles.languageButton}
        onClick={toggleDropdown}
        aria-label={t('language')}
      >
        <FaGlobe className={styles.languageIcon} />
        <span>{language.toUpperCase()}</span>
      </button>
      
      <div className={`${styles.languageDropdown} ${isOpen ? styles.show : ''}`}>
        <div 
          className={styles.languageOption} 
          onClick={() => handleLanguageChange('ar')}
        >
          {t('arabic')}
        </div>
        <div 
          className={styles.languageOption}
          onClick={() => handleLanguageChange('en')}
        >
          {t('english')}
        </div>
      </div>
    </div>
  );
};
export default SideMenu;