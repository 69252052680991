import { useTheme } from '../contexts/ThemeContext';
import { translations } from '../locales/translations';
import type { TranslationKeys, Language } from '../types/translations';

export const useTranslation = () => {
  const { language, setLanguage } = useTheme();
  
  const t = (key: keyof TranslationKeys) => {
    if (!translations[language] || !translations[language][key]) {
      console.warn(`Translation missing for key: ${key} in language: ${language}`);
      return key;
    }
    return translations[language][key];
  };
  
  return { t, language, setLanguage };
};