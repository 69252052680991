import React from 'react';
import { Container } from 'react-bootstrap';
import styles from '../styles/Home.module.css';
import { useAuth } from '../contexts/AuthContext';
import { useTranslation } from '../hooks/useTranslation';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSignInAlt, faUserCog } from '@fortawesome/free-solid-svg-icons';

const Home: React.FC = () => {
  const { userName, isAuthenticated, userRole } = useAuth();
  const { t } = useTranslation();
  
  const isAdminOrUploader = userRole === 'admin' || userRole === 'uploader';
  
  return (
    <div className={styles.homeBackground}>
      <Container className={styles.homeContainer}>
        <h1 className={styles.welcomeMessage}>
          {userName ? `${t('welcomeHome')} ${userName}!` : t('welcome')}
        </h1>
        <p className={styles.subMessage}>
          {t('welcomeSubtitle')}
        </p>
        {isAuthenticated ? (
          <div className={styles.actionButtons}>
            <Link to="/search" className={styles.actionButton}>
              <FontAwesomeIcon icon={faSearch} />
              <span>{t('search')}</span>
            </Link>
            
            {isAdminOrUploader && (
              <Link to="/admin" className={styles.actionButton}>
                <FontAwesomeIcon icon={faUserCog} />
                <span>{t('admin')}</span>
              </Link>
            )}
          </div>
        ) : (
          <Link to="/login" className={styles.loginButton}>
          <FontAwesomeIcon icon={faSignInAlt} />
          <span>{t('login')}</span>
        </Link>
        )}
      </Container>
    </div>
  );
};

export default Home;