import React from 'react';
import styles from '../styles/LoadingPage.module.css';
import logo from '../assets/logo.png'; // Make sure to add your logo image

const LoadingPage: React.FC = () => {
  return (
    <div className={styles['loading-container']}>
      <div className={styles['background-animation']}></div>
      <img src={logo} alt="Al Rakaize logo" className={styles['loading-logo']} />
    </div>
  );
};

export default LoadingPage;
