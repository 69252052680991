import React from 'react';
import styles from '../styles/Footer.module.css';
import { useTranslation } from '../hooks/useTranslation';
const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <footer className={styles.footer}>
        <div className={styles.footerContent}>
          <p>{t('copyright')}</p>
        </div>
    </footer>
  );
};

export default Footer;