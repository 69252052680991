// src/components/EmployeeCard.tsx
import React from 'react';
import { Card } from 'react-bootstrap';
import styles from '../styles/EmployeeCard.module.css';
import { formatDate } from '../utils/dateUtils';
import { useTranslation } from '../hooks/useTranslation';

interface EmployeeCardProps {
  employee: {
    [key: string]: any;
  };
}

const EmployeeCard: React.FC<EmployeeCardProps> = ({ employee }) => {
  const { t } = useTranslation();

  if (!employee) {
    return (
      <Card className={styles['employee-card']}>
        <Card.Body>
          <Card.Text>{t('noData')}</Card.Text>
        </Card.Body>
      </Card>
    );
  }

  const getFieldValue = (key: string): string => {
    if (!employee || !(key in employee)) return 'N/A';
    
    const value = employee[key];
    if (value === undefined || value === null) return 'N/A';
    if (typeof value === 'object' && 'result' in value) return String(value.result);
    if (typeof value === 'object' && 'text' in value) return value.text;
    
    // Handle dates
    const dateFields = ['تاريخ التفييز', 'تاريخ التذكرة', 'تاريخ التقديم', 'التاريخ'];
    if (dateFields.includes(key)) {
      return formatDate(String(value));
    }
    
    return String(value);
  };

  return (
    <Card className={styles['employee-card']}>
      <Card.Body>
        <Card.Title>{getFieldValue('أسم العاملة') || t('na')}</Card.Title>
        <Card.Text><strong>{t('code')}:</strong> {getFieldValue('الكود')}</Card.Text>
        <Card.Text><strong>{t('sponsorName')}:</strong> {getFieldValue('إسم الكفيل')}</Card.Text>
        <Card.Text><strong>{t('visaNumber')}:</strong> {getFieldValue('رقم التأشيرة')}</Card.Text>
        <Card.Text><strong>{t('idNumber')}:</strong> {getFieldValue('رقم الهوية')}</Card.Text>
        <Card.Text><strong>{t('contractNumber')}:</strong> {getFieldValue('رقم العقد')}</Card.Text>
        <Card.Text><strong>{t('visaDate')}:</strong> {getFieldValue('تاريخ التفييز')}</Card.Text>
        <Card.Text><strong>{t('office')}:</strong> {getFieldValue('المكتب')}</Card.Text>
        <Card.Text><strong>{t('phoneNumber')}:</strong> {getFieldValue('رقم الجوال')}</Card.Text>
        <Card.Text>
          <strong>{t('visaStatus')}:</strong> 
          <span className={styles[getFieldValue('حالة التأشيرة ') === 'تم التفييز' ? 'status-active' : 'status-inactive']}>
            {getFieldValue('حالة التأشيرة ')}
          </span>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default EmployeeCard;
