const HIJRI_YEAR_DIFF = 579;

export const convertHijriToGregorian = (dateString: string): Date => {
  try {
    // First check if it's already a valid Gregorian date
    const gregorianDate = new Date(dateString);
    if (!isNaN(gregorianDate.getTime())) {
      return gregorianDate;
    }

    // Parse Hijri date (assuming format: DD/MM/YYYY)
    const [day, month, yearStr] = dateString.split('/');
    const year = parseInt(yearStr);

    // Simple conversion (approximate)
    const gregorianYear = year + HIJRI_YEAR_DIFF;
    return new Date(gregorianYear, parseInt(month) - 1, parseInt(day));
  } catch (error) {
    console.error('Error converting date:', error);
    return new Date(dateString);
  }
};

export const formatDate = (dateString: string | undefined | null): string => {
  if (!dateString) return 'N/A';
  
  try {
    const date = convertHijriToGregorian(dateString);
    return new Intl.DateTimeFormat('ar-SA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      calendar: 'gregory'
    }).format(date);
  } catch (error) {
    console.error('Error formatting date:', error);
    return dateString;
  }
};

// Helper function to check if a string is a valid date
export const isValidDate = (dateString: string): boolean => {
  const date = new Date(dateString);
  return !isNaN(date.getTime());
};